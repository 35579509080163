// Global
$andes-theme: 'mercadolibre';

// Andes
@import '~@andes/common/index';
@import '~@andes/card/index';

body {
  background: $andes-bg-primary;
}

a,
a:link,
a:visited {
  text-decoration: none;
}

.allcategories {
  color: $andes-gray-550;
  padding: 0 15px 32px;
  margin: 0 auto;
  max-width: 1200px;
}

.allcategories__main {
  display: inline-flex;
  width: 100%;
}

.allcategories__title {
  font-size: 26px;
  font-weight: 400;
  margin: 24px auto;
  padding: 0;
  color: #000;
}

.categoriesComponent {
  width: 100%;
  padding: 32px 40px 40px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
}

.categories__container {
  margin-bottom: 40px;
}

.categories__title {
  font-size: 17px;
  font-weight: 600;
  color: $andes-gray-900;
  margin: 0 0 8px;
}

.categories__list {
  margin: 0 auto;
  padding-bottom: 40px;
  column-count: 4;
  column-gap: 15px;
  padding-left: 0;
}

.categories__container:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.categories__item {
  font-size: 14px;
  line-height: 24px;
  list-style-type: none;
  width: 25%\9;
  float: left\9;
}

.categories__item a {
  color: $andes-gray-550;
}

.categories__title a:hover,
.categories__item a:hover {
  color: $andes-blue-400;
}

/* IE 9 only */
@media screen and (width >= 0\0) and (resolution >= 0.001dpcm) {
  .categories__item {
    width: 25%\9;
    float: left\9;
  }
}
